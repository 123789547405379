import axios from "axios";
import headers from '../authHeaders';
import store from '../../store';

export default {    
    async trackClick(actionName: string) {
        return axios({
            method: "post",
            url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/tracking/' + actionName + '/',
            headers: await headers.getAuthHeaders()
        }).then(response => {
            return response.data;
        });
    },
    async trackClickWithReferer(actionName: string) {
        const refererUrl = { value: store.state.currentUser.refererSource };
        return axios({
            method: "post",
            url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/tracking/referer/' + actionName + '/',
            headers: await headers.getAuthHeaders(),
            data: refererUrl
        }).then(response => {
            return response.data;
        });
    },
    async trackClickWithValue(actionName: string, value: string) {
        return axios({
            method: "post",
            url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/tracking/' + actionName + '/' + value + '/',
            headers: await headers.getAuthHeaders()
        }).then(response => {
            return response.data;
        });
    },
    async trackClickWithQhid(actionName: string, qhid: number) {
        return axios({
            method: "post",
            url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/tracking/withmember/' + actionName + '/' + qhid + '/',
            headers: await headers.getAuthHeaders()
        }).then(response => {
            return response.data;
        });
    },
    async trackClickWithQhidAndValue(actionName: string, qhid: number, value: string) {
        return axios({
            method: "post",
            url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/tracking/withmember/${actionName}/${qhid}/${value}/`,
            headers: await headers.getAuthHeaders()
        }).then(response => {
            return response.data;
        });
    },
    async trackClickWithNetworkAndQhid(actionName: string, qhid: number, networkId: number, networkName: string) {
        return axios({
            method: "post",
            url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/tracking/withnetwork/${actionName}/${qhid}/${networkId}/${networkName}/`,
            headers: await headers.getAuthHeaders()
        }).then(response => {
            return response.data;
        });
    },
    async trackPaging(actionName: string, pagingEvent: {source: string, from: number, to: number}) {
        return axios({
            method: "post",
            url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/tracking/paging/' + actionName + '/',
            headers: await headers.getAuthHeaders(),
            data: pagingEvent
        }).then(response => {
            return response.data;
        });
    },
    async trackReferer(url: string) {
        if (store.state.currentUser.refererSource === null )
        {
            store.commit('currentUser/setRefererSource', url);
            this.trackClickWithReferer('Referer');
        }
    },
    async trackPdfError(pdfName: string, qhid: number, error: string) {
        return axios({
            method: "post",
            url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/tracking/pdfError',
            headers: await headers.getAuthHeaders(),
            data: { 'pdfName': pdfName, 'qhid': qhid, 'error': error}
        }).then(response => {
            return response.data;
        });
    },
}
