import axios from "axios";
import headers from '../authHeaders';

import { SearchRequest } from "./member.js";

import { MaternityAssessment } from "./MaternityAssement.js";
import Index from "@/components/shared/document-uploader/index.vue";

interface AuthorizationWorkflow {
  authorizationWorkflowId: number
  name: string
  trackingKey: string
  resourceKey: string
  posCodes: {
    code: string
    description: string
    requireUnits: boolean
    isInpatient: boolean
  }[]
}

interface AuthEligibility {
  qhid: number
  hasCoverage: boolean
  isSecureGroup: boolean
  phoneNumber: string
}

interface AddDocumentResponse {
  documentKey: string
  documentPutUrl: string
  documentPutHeaders: { [key: string]: string }
}

interface ViewAuthorizationRequest {
  qhid: number
  year: number | null | undefined
  term: string
  pageSize: number
  pageNumber: number
  sortClause: string
  loginId: string
}

export interface ViewAuthorizationResponse {
  authorizations: ViewAuthorizationSummary[]
  years: number[]
}

export interface ViewAuthorizationSummary {
  authorizationNumber: string
  providerName: string
  facilityName: string
  status: string
  serviceFrom: Date
  serviceTo: Date
  totalRows: number
}

export default { 
  async getAuthorization(id: number) {
    return axios({
      method: "get",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/auth/${id}/`,
      headers: await headers.getAuthHeaders()
    }).then(response => {
      return response.data;
    });
  },
  async getAuthorizationWorkflows(): Promise<AuthorizationWorkflow[]> {
    return axios({
      method: "get",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/auth/workflows/',
      headers: await headers.getAuthHeaders()
    }).then(response => {
      return response.data;
    });
  },
  async getMaternityQuestions(qhid: number): Promise<MaternityAssessment> {
    return axios({
      method: "get",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/auth/maternity/${qhid}`,
      headers: await headers.getAuthHeaders()
    }).then(response => {
      return response.data;
    });
  },
	async memberSearch(data: SearchRequest & { expectedServiceDate: string }): Promise<AuthEligibility> {
		return axios({
			method: "post",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/auth/member/search/',
			data: data,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
	async checkCoverage(data: { qhid: number, expectedServiceDate: string }): Promise<AuthEligibility> {
		return axios({
			method: "post",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/auth/coverage/',
			data: data,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
	async getClinicalUploadURL(data: { qhid: number, groupId: number, fileName: string, authorizationId: string }): Promise<AddDocumentResponse> {
		return axios({
			method: "post",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/auth/clinicals/',
			data: data,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
	async deleteClinical(qhid: number, key: string) {
		return axios({
			method: "delete",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/auth/clinicals/${qhid}/${key}`,
			headers: await headers.getAuthHeaders()
		}).then(response => {
			return response.data;
		});
	},
  async getClinical(qhid: number, key: string) {
    return axios({
      method: "get",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/auth/clinicals/${qhid}/${key}`,
      headers: await headers.getAuthHeaders()
    }).then(response => {
      return response.data;
    });
  },
  async saveAuthorization(data: any) {
    return axios({
      method: "post",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/auth/',
      headers: await headers.getAuthHeaders(),
      data: data
    }).then(response => {
      return response.data;
    });
  },
  async updateAuthorization(data: any) {
    return axios({
      method: "post",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/auth/UpdateAuthorization',
      headers: await headers.getAuthHeaders(),
      data: data
    }).then(response => {
      return response.data;
    });
  },
  async saveClinicals(data: any) {
    return axios({
      method: "post",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/auth/clinicals/save',
      headers: await headers.getAuthHeaders(),
      data: data
    });
  },
  async getViewAuthSummaries(data: ViewAuthorizationRequest): Promise<ViewAuthorizationResponse | null> {
    return axios({
      method: "post",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/auth/viewauthsummaries',
      headers: await headers.getAuthHeaders(),
      data: data
    }).then(response => {
      return response.data;
    });
  },
  async getAuthsExist(qhid: number): Promise<number> {
    return axios({
      method: "get",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/auth/getauthsexist/${qhid}`,
      headers: await headers.getAuthHeaders()
    }).then(response => {
      return response.data;
    });
  },
  async GetRecentAuthorizations(qhid: number) {
    return axios({
      method: "get",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/auth/RecentAerialAuthorizations/${qhid}`,
      headers: await headers.getAuthHeaders()
    }).then(response => {
      return response.data;
    });
  },
  async getDeterminationLetter(qhid: number, authId: string): Promise<string> {
    return axios({
      method: "get",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/auth/determinationletter/${qhid}/${authId}`,
      headers: await headers.getAuthHeaders()
    }).then(response => {
      return response.data;
    });
  },
  async getCptCodesNotRequiringAuth(): Promise<string[]> {
    return axios({
      method: "get",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/auth/CptCodesNotRequiringAuth`,
      headers: await headers.getAuthHeaders()
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("GetCptCodesNotRequiringAuth Error:", error);
        throw error;
      });
  },
	async isProviderMriSpecialty(qhproviderid: string): Promise<boolean> {
		return axios({
			method: "get",
			url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/auth/MriProviderSpecialty/${qhproviderid}/`,
			headers: await headers.getAuthHeaders()
			}).then(response => {
				return response.data;
			});
	},
  async getAuthIntakeObject(authId: string) {
    return axios({
      method: "get",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/auth/getAuth/${authId}`,
      headers: await headers.getAuthHeaders(),
    }).then(response => {
      return response.data;
    });
  },
  async getAuthByAuthId(authId: string) {
    return axios({
      method: "get",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/auth/${authId}`,
      headers: await headers.getAuthHeaders(),
    }).then(response => {
      return response.data;
    });
  },
  async getAuthDocuments(qhid: number, authId: string) {
    return axios({
      method: "get",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/auth/getDocumentsMetaData/${qhid}/${authId}`,
      headers: await headers.getAuthHeaders(),
    }).then(response => {
      return response.data;
    });
  },
  async isAuthAutoApproved(authId: string) {
    return axios({
      method: "get",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + `/api/auth/isAuthAutoApproved/${authId}`,
      headers: await headers.getAuthHeaders(),
    }).then(response => {
      return response.data;
    });
  }
}
