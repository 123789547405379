import axios from "axios";
import headers from '../authHeaders';

interface Settings {
  internalTimeOutMinutes: number | null
  externalTimeOutMinutes: number | null
  externalTimeOutDialogMinutes: number | null
  googleAnalyticsKey: string | null
  states: string[] | null
  securityQuestions: {
    group1: string[]
    group2: string[]
    group3: string[]
  } | null
  icdLookupSettings: {
    appId: string
    searchApiKey: string
    indexName: string
  } | null
  cptLookupSettings: {
    appId: string
    searchApiKey: string
    indexName: string
  } | null
  enableExternalProvidersAerial: boolean
  externalProvidersAerialStandardWorkflowGroups: number[] | null
  externalProvidersExcludeGroupsAerialUnplannedWorkflow: number[] | null
  externalProvidersAerialMaternityWorkflowGroups: number[] | null
  externalProvidersExcludeGroupsAerialDmeWorkflow: number[] | null
  registrationUsesSms: boolean
  externalProvidersPostAuthSubmissionGroupExclude: number[] | null
  externalProvidersEnableAuthEdits: boolean
  enableExternalProvidersPostAuthSubmission: boolean
  enableExternalProvidersAuthOverviewTab: boolean
  isAerialOutage: string | null
  aerialOutageMessage: string | null
  externalProviderCPTCodeSearchEnabled: boolean
  enableExternalProviderSOCLinkBox: boolean
  externalProvidersSOCMessageRawHTML: string | null
  benefitCheckerBaseUrl: string | null
  benefitCheckerAdminApiKey: string | null
  externalProvidersAutoApprovalMessage: string | null
  globalBannerMessage: string | null
  enableUploadDocTabUnderManageAuth: boolean
  enableNewMaternityView: boolean
}

export default { 
  async getSettings(): Promise<Settings> {
    const response = await axios({
      method: "get",
      url: (process.env.NODE_ENV == "production" ? process.env.VUE_APP_EXTERNALPROVIDERS_API : "externalproviders") + '/api/settings/',
      headers: await headers.getAuthHeaders()
    });
    return response.data;
  }
}
